/**
 * How to use:
 * Add a div with attribute "data-a-integer" around your input-integer element
 * Example:
 *  <div class="integer" data-a-integer>
 *      <input id="integerInputName" class="integer-input" data-a-integer-input
 *          type="number" name="number" min="0" max="100" required>
 *      <div class="integer-input-controls">
 *          <button class="integer-input-decrease" data-a-integer-decrease type="button">-</button>
 *          <button class="integer-input-increase" data-a-integer-increase type="button">+</button>
 *      </div>
 *  </div>
 */

export class IntegerComponent {
    private integerElements: NodeListOf<HTMLElement>; // data-a-integer
    private inputElement: HTMLInputElement; // data-a-integer-input
    private increaseButtonElement: HTMLButtonElement; // data-a-integer-decrease
    private decreaseButtonElement: HTMLButtonElement; // data-a-integer-increase

    constructor() {
        this.integerElements = document.querySelectorAll('[data-a-integer]');

        if (!this.integerElements) return;

        this.integerElements.forEach(element => {
            this.inputElement = element.querySelector('[data-a-integer-input]') as HTMLInputElement;
            this.decreaseButtonElement = element.querySelector('[data-a-integer-decrease]') as HTMLButtonElement;
            this.increaseButtonElement = element.querySelector('[data-a-integer-increase]') as HTMLButtonElement;

            if (
                this.increaseButtonElement
                && this.decreaseButtonElement
                && this.inputElement
            ) {
                this.incrementItem(this.increaseButtonElement, this.inputElement);
                this.decrementItem(this.decreaseButtonElement, this.inputElement);
            }
        })
    }

    private incrementItem(buttonElement: HTMLButtonElement, inputElement: HTMLInputElement) {
        buttonElement.addEventListener('click', (e) => {
            e.preventDefault();

            const currentValue = Number(inputElement.value) || 0;
            
            inputElement.value = (currentValue + 1).toString();
        })
    }

    private decrementItem(buttonElement: HTMLButtonElement, inputElement: HTMLInputElement) {
        buttonElement.addEventListener('click', (e) => {
            e.preventDefault();

            const currentValue = Number(inputElement.value) || 0;

            if (currentValue > 0) {
                inputElement.value = (currentValue - 1).toString();
            }
        })
    }
}