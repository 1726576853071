'use strict';

import { AlpineJSComponent } from './components/alpineJs.component';
new AlpineJSComponent();

import { NavigationComponent } from './components/navigation.component';
new NavigationComponent();

import { IntegerComponent } from './components/integer.component';
new IntegerComponent();

/**
 * SCSS import
 * DO NOT REMOVE !!
 */

import '../scss/main.scss';
