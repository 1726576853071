export class NavigationComponent {
    private bodyElement: HTMLBodyElement;
    private mobilenavToggle: HTMLButtonElement;
    private subnavToggles: NodeListOf<HTMLButtonElement>;
    private hasPopupElements: NodeListOf<HTMLElement>;
    private headerOverlay: HTMLElement;

    constructor() {
        this.bodyElement = document.body as HTMLBodyElement;
        this.mobilenavToggle = document.querySelector('[data-a-mobilenav-toggle]');
        this.subnavToggles = document.querySelectorAll('[data-a-subnav-toggle]');
        this.hasPopupElements = document.querySelectorAll('[data-a-mainnav] [aria-haspopup], [data-a-topnav] [aria-haspopup]');
        this.headerOverlay = document.querySelector('[data-a-header-overlay]');

        this.initialize();
    }

    private initialize(): void {
        if (this.toggleMobilenav) {
            this.toggleMobilenav();
        }

        // Toggle subnav on mobile devices
        if (this.subnavToggles.length > 0) {
            this.toggleSubnav();
        }

        if (this.hasPopupElements.length > 0) {
            this.showSubnavOnHover();
        }

        if (this.headerOverlay) {
            this.closeOverlay();
        }
    }

    private toggleMobilenav(): void {
        let scrollPosition = window.scrollY;
        // let scrollPosition = document.documentElement.scrollTop;

        this.mobilenavToggle.addEventListener('click', () => {
            if (this.bodyElement.classList.contains('has-open-navigation')) {
                this.toggleNavigation(false);
                // this.retainCurrentScrollPosition(scrollPosition);
            } else {
                scrollPosition = window.scrollY;
                // scrollPosition = document.documentElement.scrollTop;
                this.toggleNavigation(true);
                // this.retainCurrentScrollPosition(scrollPosition);
            }
            this.retainCurrentScrollPosition(scrollPosition);
        });
    }

    private toggleNavigation(show: boolean): void {
        this.bodyElement.classList.toggle('has-open-navigation', show);
        this.mobilenavToggle.setAttribute('aria-expanded', show ? 'true' : 'false');
    }

    private toggleSubnav(): void {
        this.subnavToggles.forEach((button: HTMLButtonElement) => {
            button.addEventListener('click', () => {
                // TODO: .closest() instead of parentElement 
                const parentElement = button.parentElement;
                if (!parentElement) return;

                const subnav = parentElement.querySelector('[data-a-subnav]') as HTMLUListElement;
                if (!subnav) return;

                this.toggleSubnavState(subnav);
                this.toggleButtonState(button);
            });
        })
    }

    private toggleSubnavState(subnav: HTMLUListElement): void {
        // TODO: .closest() instead of parentElement 
        const parentElement = subnav.parentElement;
        if (!parentElement) return;

        const isHidden = subnav.getAttribute('aria-hidden') === 'true';

        subnav.setAttribute('aria-hidden', isHidden ? 'false' : 'true');
        parentElement.classList.toggle('is-opened', isHidden);

        // If we're closing this subnav, close all nested subnavs
        if (!isHidden) {
            const nestedSubnavs = subnav.querySelectorAll<HTMLUListElement>('[data-a-subnav][aria-hidden="false"]');
            nestedSubnavs.forEach(subnav => this.toggleSubnavState(subnav));
        }
    }

    private showSubnavOnHover(): void {
        this.hasPopupElements.forEach(element => {
            const parentElement = element.parentElement;

            parentElement.addEventListener('mouseenter', () => {
                if (!document.querySelector('.has-open-navigation')) {
                    const subnav = parentElement.querySelector('[data-a-subnav]') as HTMLUListElement;

                    if (!subnav) return;

                    this.toggleSubmenu(subnav, true);
                }
            })

            parentElement.addEventListener('mouseleave', () => {
                if (!document.querySelector('.has-open-navigation')) {
                    const subnav = parentElement.querySelector('[data-a-subnav]') as HTMLUListElement;

                    if (!subnav) return;

                    this.toggleSubmenu(subnav, false);
                }
            })
        })
    }

    private toggleSubmenu(dropdown: HTMLUListElement, show: boolean): void {
        dropdown.setAttribute('aria-hidden', show ? 'false' : 'true');
    }

    private toggleButtonState(button: HTMLButtonElement): void {
        const expanded = button.getAttribute('aria-expanded') === 'true';
        button.setAttribute('aria-expanded', expanded ? 'false' : 'true');
    }

    private retainCurrentScrollPosition(scrollPosition: number): void {
        /// Keep the scrollPosition when toggling navigation with sticky header.
        document.documentElement.scrollTo(0, scrollPosition);
        document.body.scrollTo(0, scrollPosition);
    }

    private closeOverlay(): void {
        this.headerOverlay.addEventListener('click', () => {
            this.bodyElement.classList.remove('has-open-navigation');
        })
    }
}